import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select/dist/js/tom-select.base.js'
import ClearButton from 'tom-select/dist/js/plugins/clear_button.js'
import RemoveButton from 'tom-select/dist/js/plugins/remove_button.js'
import NoBackspaceDelete from 'tom-select/dist/js/plugins/no_backspace_delete.js'

TomSelect.define('clear_button', ClearButton)
TomSelect.define('remove_button', RemoveButton)
TomSelect.define('no_backspace_delete', NoBackspaceDelete)

export default class extends Controller {
  ALLOWED_PLUGINS = {
    clear_button: {
      title: "Tout supprimer"
    },
    remove_button: {
      title: "Supprimer élement"
    },
    no_backspace_delete: {}
  }

  ALLOWED_PRELOAD_VALUES = ['true', 'false', 'focus']

  static values = {
    endpoint: String,
    preload: String,
    loadOnce: { type: Boolean, default: true },
    plugins: { type: Array, default: [] }
  }

  static targets = ["input"]

  connect () {
    if (this.hasPreloadValue && !this.ALLOWED_PRELOAD_VALUES.includes(this.preloadValue)) console.warn(`${this.identifier}: invalid value for preload`)
    if (this.hasPreloadValue && !this.hasEndpointValue) console.warn(`${this.identifier}: preload data-value specified without endpoint data-value !`)

    this.tomSelect = new TomSelect(this.inputElement, this.defaultOptions)
  }

  disconnect () {
    this.tomSelect.destroy()
  }

  selectOptGroup (event) {
    event.target.parentElement.querySelectorAll(`[data-${this.identifier}-target='option']`).forEach(option => option.click())
  }

  toggleOptGroupHighlighting (event) {
    if (event.type === "mouseover") {
      event.target.style.cursor = 'pointer'
      Array.from(event.target.parentElement.children).forEach(child => child.classList.add('active'))
    } else if (event.type === "mouseleave") {
      event.target.style.cursor = 'auto'
      Array.from(event.target.parentElement.children).forEach(child => child.classList.remove('active'))
    }
  }

  toggleActive (event) {
    event.detail.value ? this.tomSelect.disable() : this.tomSelect.enable()
  }

  _url (query) {
    const url = new URL(this.endpointValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append("q", query)
    url.search = params.toString()
    return url
  }

  get inputElement () {
    return this.hasInputTarget ? this.inputTarget : this.element
  }

  // TomSelect options: https://tom-select.js.org/docs
  get defaultOptions () {
    const options = {
      maxOptions: null,
      preload: this.preloadOption,
      searchField: ['text', 'hidden_text'],
      ...this.pluginsOptions,
      ...this.remoteOptions,
      ...this.renderOptions
    }

    return options
  }

  get pluginsOptions () {
    const options = {}

    this.pluginsValue.forEach(pluginName => {
      if (Object.keys(this.ALLOWED_PLUGINS).includes(pluginName)) {
        options[pluginName] = this.ALLOWED_PLUGINS[pluginName]
      }
    })

    return { plugins: options }
  }

  get remoteOptions () {
    if (!this.hasEndpointValue) return {}

    return {
      load: (query, callback) => {
        if (this.loadOnceValue && this.tomSelect.loading > 1) {
          callback()
          return
        }

        fetch(this._url(query))
          .then(response => response.json())
          .then(json => {
            callback(json.options, json?.optgroups)
            if (this.loadOnceValue) this.tomSelect.settings.load = null
          }).catch(() => {
            callback()
          })
        }
    }
  }

  get renderOptions () {
    const options = {}

    options.no_results = (data, escape) => {
      return '<div class="no-results">Aucun résultat pour "' + escape(data.input) + '"</div>'
    }

    if (this.inputElement.multiple) {
      options.option = (data, escape) => {
        return `<div data-${this.identifier}-target="option"> ${escape(data.text)} </div>`
      }
      options.optgroup_header = (data, escape) => {
        const dataAction = `data-action='click->${this.identifier}#selectOptGroup mouseover->${this.identifier}#toggleOptGroupHighlighting mouseleave->${this.identifier}#toggleOptGroupHighlighting'`
        return `<div class="optgroup-header" role="button" ${dataAction}> ${escape(data.label)} </div>`
      }
      options.optgroup = (data) => {
        const optgroup = document.createElement('div')
        optgroup.className = 'optgroup'
        // Remove optgroup_header div from optgroup options when there is only one option
        if (data.options.childElementCount === 2 && data.options.firstChild.classList.contains('optgroup-header')) {
          data.options.firstElementChild.remove()
        }
        optgroup.appendChild(data.options)
        return optgroup
      }
    }

    return { render: options }
  }

  get preloadOption () {
    if (this.hasPreloadValue && this.ALLOWED_PRELOAD_VALUES.includes(this.preloadValue)) {
      if (this.preloadValue === 'true') {
        return true
      } else if (this.preloadValue === 'false') {
        return false
      } else {
        return this.preloadValue
      }
    }
  }
}
